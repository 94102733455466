import __request__ from 'routing/request';
interface ArchiveConfig {
    readonly segment_checksum_type: 'NONE' | 'CRC32' | 'MD5' | 'SHA1' | 'SHA256';
    readonly segment_compression_type: 'NONE' | 'GZIP' | 'SNAPPY' | 'LZ4' | 'ZSTD';
    readonly parallelize_archive_creation: boolean;
    readonly histogram_bucket_size: number;
    readonly restore_index_batch_size: number;
    readonly archive_failure_threshold: number;
    readonly segment_filename_prefix: string;
    readonly restrict_to_leader: boolean;
    readonly excluded_streams: string[];
    readonly metadata_filename: string;
    readonly backend_id: string;
    readonly retention_time: number;
    readonly max_segment_size: number;
    readonly archive_path: string;
}
/**
 * Get archive configuration
 */
export function get(): Promise<ArchiveConfig> {
    return __request__('GET', '/plugins/org.graylog.plugins.archive/config', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update archive configuration
 */
export function update(config: ArchiveConfig): Promise<ArchiveConfig> {
    return __request__('PUT', '/plugins/org.graylog.plugins.archive/config', config, {}, {
        'Accept': ['application/json']
    });
}
