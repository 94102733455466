import __request__ from 'routing/request';
interface TimeRange {
    readonly type: string;
}
interface DataWarehouseQueryResult {
    readonly stats: ExecutionStats;
    readonly messages: DataWarehouseRow[];
    readonly status: 'OK' | 'ERROR' | 'TIMEOUT' | 'CANCELLED';
}
interface RetrievalFilter {
    readonly value: Object;
    readonly field_name: string;
}
interface DataWarehouseRow {
    readonly message: {
        readonly [_key: string]: Object;
    };
}
interface DataWarehouseQueryRequest {
    readonly search_after: SearchAfter;
    readonly timerange: TimeRange;
    readonly field_filters: RetrievalFilter[];
    readonly stream_id: string;
    readonly limit: number;
    readonly inclusion_type: 'DATA_WAREHOUSE_ONLY' | 'INDEXER_ONLY' | 'BOTH';
    readonly fields: string[];
    readonly timeout: number;
    readonly operator: 'AND' | 'OR';
}
interface SearchAfter {
    readonly id: string;
    readonly timestamp: string;
}
interface SearchJobState {
    readonly owner: string;
    readonly result: Result;
    readonly error_message: string;
    readonly updated_at: string;
    readonly created_at: string;
    readonly progress: number;
    readonly id: string;
    readonly type: 'DATA_LAKE';
    readonly search_id: string;
    readonly executing_node: string;
    readonly status: 'RUNNING' | 'DONE' | 'CANCELLATION_REQUESTED' | 'CANCELLED' | 'TIMEOUT' | 'EXPIRED' | 'ERROR' | 'RESET';
}
interface Result {
    readonly name: string;
    readonly id: string;
    readonly type: string;
}
interface ExecutionStats {
    readonly duration: string;
    readonly size_of_opened_files: number;
    readonly opened_files: number;
    readonly records_filtered_in_memory: number;
    readonly files_matching_filter_expression: number;
}
/**
 * Query data lake stream
 */
export function query(request?: DataWarehouseQueryRequest): Promise<DataWarehouseQueryResult> {
    return __request__('POST', '/plugins/org.graylog.plugins.datawarehouse/data_warehouse/query', request, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get active (latest) data lake query job for the user
 */
export function activeQuery(): Promise<SearchJobState> {
    return __request__('GET', '/plugins/org.graylog.plugins.datawarehouse/data_warehouse/query/active_query', null, {}, {
        'Accept': ['application/json']
    });
}
