import React, { useMemo, useState, useCallback } from 'react';
import { styled, css } from 'styled-components';
import * as Immutable from 'immutable';

import { DocumentTitle, Spinner } from 'components/common';
import type { FormValues } from 'data-lake/preview/SearchBar';
import SearchBar from 'data-lake/preview/SearchBar';
import PageContentLayout from 'components/layout/PageContentLayout';
import useSearchConfiguration from 'hooks/useSearchConfiguration';
import type { TimeRange } from 'views/logic/queries/Query';
import AutoRefreshProvider from 'views/components/contexts/AutoRefreshProvider';
import { Alert, Row } from 'components/bootstrap';
import SearchFeatureExplanation from 'data-lake/preview/SearchFeatureExplanation';
import useFieldTypes from 'data-lake/hooks/useFieldTypes';
import DLLogViewWidget from 'data-lake/preview/DLLogViewWidget';
import DataLakeWidget from 'data-lake/logic/DataLakeWidget';
import DataLakeWidgetConfig from 'data-lake/logic/DataLakeWidgetConfig';
import Sidebar from 'views/components/sidebar/Sidebar';
import DataLakeHighlightingRules from 'data-lake/preview/DataLakeHighlightingRules';
import type HighlightingRule from 'views/logic/views/formatting/highlighting/HighlightingRule';
import HighlightingRulesContext from 'views/components/contexts/HighlightingRulesContext';
import useOnBrowserNavigation from 'data-lake/hooks/useOnBrowserNavigation';
import type { FieldFilters, FieldsOperator } from 'data-lake/Types';
import useFiltersFromURLParams from 'data-lake/preview/useFiltersFromURLParams';
import type { SearchReducerAction } from 'data-lake/preview/useSearchReducer';
import useSearchReducer from 'data-lake/preview/useSearchReducer';
import fetchSearchResults, { PAGE_SIZE } from 'data-lake/preview/fetchSearchResults';
import type { FieldTypeMappingsList } from 'views/logic/fieldtypes/types';
import FieldTypesContext from 'views/components/contexts/FieldTypesContext';
import { onInitializingTimerange } from 'views/components/TimerangeForForm';
import useUserDateTime from 'hooks/useUserDateTime';

const DEFAULT_WIDGET_FIELDS = ['timestamp', 'source', 'message', 'streams'];
const FIXED_WIDGET_FIELDS = ['id', 'timestamp'];

const StyledPageContentLayout = styled(PageContentLayout)`
  .page-content-grid {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: auto;
  }
`;
const GridContainer = styled.div`
  display: flex;
  height: 100%;
`;

const FullHeightRow = styled(Row)(
  ({ theme }) => css`
    flex: 1;
    overflow: auto;

    > *:not(:last-child) {
      margin-bottom: ${theme.spacings.sm};
    }
  `,
);

const StyledAlert = styled(Alert)`
  margin: 0;
`;

const initialWidget = new DataLakeWidget(
  'data-lake-widget-id',
  new DataLakeWidgetConfig(undefined, Immutable.OrderedSet(DEFAULT_WIDGET_FIELDS), PAGE_SIZE, 'DESC', 'timestamp'),
  undefined,
  undefined,
  undefined,
);

const useResetOnBrowserNavigation = (dispatchSearchState: React.Dispatch<SearchReducerAction>) => {
  const onBrowserNavigation = useCallback(() => {
    dispatchSearchState({ type: 'RESET_SEARCH_STATE' });
  }, [dispatchSearchState]);

  useOnBrowserNavigation(onBrowserNavigation);
};

const DataLakePreview = () => {
  const { formatTime } = useUserDateTime();
  const [highlightingRules, setHighlightingRules] = useState<Array<HighlightingRule>>([]);
  const { filters, setFilters } = useFiltersFromURLParams();
  const [widget, setWidget] = useState<DataLakeWidget>(initialWidget);
  const [editing, setEditing] = useState(false);
  const [{ messages, error, isFetching, hasBeenSubmitted }, dispatchSearchState] = useSearchReducer();
  const searchRequestParams = useMemo(
    () => ({
      fields: [...FIXED_WIDGET_FIELDS, ...widget.config.fields.toArray()],
      filters,
    }),
    [filters, widget.config.fields],
  );

  const executeSearch = useCallback(
    async ({
      fields,
      stream,
      timerange,
      fieldFilters,
      fieldsOperator,
    }: {
      fields: Array<string>;
      stream: string;
      timerange: TimeRange;
      fieldFilters: Array<FieldFilters>;
      fieldsOperator: FieldsOperator;
    }) => {
      dispatchSearchState({ type: 'SET_IS_FETCHING' });

      return fetchSearchResults({ fields, fieldsOperator, stream, timerange, fieldFilters })
        .then((newMessages) => dispatchSearchState({ type: 'SET_MESSAGES', payload: newMessages }))
        .catch((newError) => dispatchSearchState({ type: 'SET_ERROR', payload: newError }));
    },
    [dispatchSearchState],
  );

  const submitSearchBarForm = useCallback(
    (formValues: FormValues) => {
      dispatchSearchState({ type: 'SET_IS_FETCHING' });

      setFilters(formValues);

      return executeSearch({
        fields: searchRequestParams.fields,
        stream: formValues.stream,
        timerange: formValues.timerange,
        fieldFilters: formValues.fields?.fieldFilters,
        fieldsOperator: formValues.fields?.operator,
      });
    },
    [dispatchSearchState, executeSearch, searchRequestParams.fields, setFilters],
  );

  const reexecuteSearch = useCallback(() => {
    executeSearch({
      fields: searchRequestParams.fields,
      stream: searchRequestParams.filters.stream,
      timerange: searchRequestParams.filters.timerange,
      fieldFilters: searchRequestParams.filters.fields?.fieldFilters,
      fieldsOperator: searchRequestParams.filters.fields?.operator,
    });
  }, [
    executeSearch,
    searchRequestParams.fields,
    searchRequestParams.filters.fields?.fieldFilters,
    searchRequestParams.filters.fields?.operator,
    searchRequestParams.filters.stream,
    searchRequestParams.filters.timerange,
  ]);

  const onChangeWidget = useCallback((newWidget: DataLakeWidget) => {
    setWidget(newWidget);

    return Promise.resolve();
  }, []);

  const toggleEdit = useCallback(() => setEditing((cur) => !cur), []);

  const onReset = useCallback(() => {
    dispatchSearchState({ type: 'RESET_SEARCH_STATE' });
  }, [dispatchSearchState]);

  const highlightSidebarSection = useCallback(
    () => <DataLakeHighlightingRules setHighlightingRules={setHighlightingRules} />,
    [],
  );

  useResetOnBrowserNavigation(dispatchSearchState);

  return (
    <HighlightingRulesContext.Provider value={highlightingRules}>
      <GridContainer>
        <Sidebar
          sections={[
            {
              key: 'highlighting',
              icon: 'format_paragraph' as const,
              title: 'Highlighting',
              content: highlightSidebarSection,
            },
          ]}
          enableSidebarPinning={false}
          actions={[]}
          title="Data Lake Preview"
        />
        <StyledPageContentLayout>
          <AutoRefreshProvider onRefresh={reexecuteSearch}>
            <SearchFeatureExplanation />
            <SearchBar
              onSubmit={submitSearchBarForm}
              onReset={onReset}
              initialValues={{ ...filters, timerange: onInitializingTimerange(filters.timerange, formatTime) }}
              isLoading={isFetching}
            />
            <FullHeightRow>
              {isFetching && <Spinner />}
              {error && <StyledAlert bsStyle="danger">{error.responseMessage}</StyledAlert>}
              {!isFetching && !error && (
                <>
                  {!hasBeenSubmitted && (
                    <StyledAlert bsStyle="info">
                      Submit the selected filter to see a preview of log messages for the selected data lake.
                    </StyledAlert>
                  )}
                  {hasBeenSubmitted && (
                    <>
                      {messages?.length > 0 ? (
                        <DLLogViewWidget
                          searchRequestParams={searchRequestParams}
                          messages={messages}
                          isFetching={isFetching}
                          onToggleEdit={toggleEdit}
                          onChangeWidget={onChangeWidget}
                          widget={widget}
                          editing={editing}
                        />
                      ) : (
                        <StyledAlert>No messages have been found for selected filters.</StyledAlert>
                      )}
                    </>
                  )}
                </>
              )}
            </FullHeightRow>
          </AutoRefreshProvider>
        </StyledPageContentLayout>
      </GridContainer>
    </HighlightingRulesContext.Provider>
  );
};

const DataLakePreviewPage = () => {
  const { config } = useSearchConfiguration();
  const { isInitialLoading: isInitialLoadingFields, data: fieldTypes } = useFieldTypes();
  const fieldTypesContextValue = useMemo(
    () => ({
      all: Immutable.List(fieldTypes) ?? Immutable.List(),
      queryFields: Immutable.Map<string, FieldTypeMappingsList>(),
    }),
    [fieldTypes],
  );

  return (
    <DocumentTitle title="Data Lake Preview">
      {config && !isInitialLoadingFields ? (
        <FieldTypesContext.Provider value={fieldTypesContextValue}>
          <DataLakePreview />
        </FieldTypesContext.Provider>
      ) : (
        <Spinner />
      )}
    </DocumentTitle>
  );
};

export default DataLakePreviewPage;
