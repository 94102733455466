import * as React from 'react';
import { useContext, useCallback } from 'react';
import styled from 'styled-components';
import type * as Immutable from 'immutable';
import isNil from 'lodash/isNil';
import isNaN from 'lodash/isNaN';

import CustomHighlighting from 'views/components/highlighting/CustomHighlighting';
import DecoratedValue from 'views/components/messagelist/decoration/DecoratedValue';
import TypeSpecificValue from 'views/components/TypeSpecificValue';
import type { FieldTypeMappingsList } from 'views/logic/fieldtypes/types';
import FieldType from 'views/logic/fieldtypes/FieldType';
import useAutoRefresh from 'views/hooks/useAutoRefresh';
import type { LogViewMessage, InfiniteScrollDirection } from 'logview/types';
import LoadMoreRow from 'logview/components/LoadMoreRow';
import { isInfiniteScrollUp } from 'logview/helpers';

import onTableScroll from './OnTableScroll';
import LogViewRow from './LogViewRow';
import LogViewCell from './LogViewCell';
import LogViewHeader from './LogViewHeader';
import ListStateContext from './contexts/ListStateContext';
import MessageDetailsContext from './contexts/MessageDetailsContext';
import type { PageRefs, TableRef } from './LogViewWidget';

import { CELL_SEP } from '../Constants';
import type LogViewWidgetConfig from '../logic/LogViewWidgetConfig';

const isNullish = (v: unknown) => (isNaN(v) || isNil(v));
export const LOADING_ROW_HEIGHT = 40;
const compareMessage = ({ message: { _id: m1 } }, message) => m1 === message?.message._id;

const Table = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-anchor: none;
  overflow-y: auto;
  position: relative;
`;

const TableInner = styled.div`
  /* Remove bottom border of last row of last page */
  > *:last-child > *:last-child {
    border-bottom: 0;
  }
`;

const _getBottomPageHeight = (lastVisiblePageIndex: number, pageRefs: PageRefs) => (
  pageRefs.current[lastVisiblePageIndex]?.offsetHeight || 0
);

type MessageProps = {
  tableHeader: Immutable.OrderedSet<string>,
  setActiveMessageDetailsId: (messageId: string) => void,
  highlight: boolean,
  completeMessage: LogViewMessage,
  fields: FieldTypeMappingsList,
}

const isDecoratedField = (field: string, decorationStats: LogViewMessage['decoration_stats']) => decorationStats
  && (decorationStats.added_fields[field] !== undefined || decorationStats.changed_fields[field] !== undefined);

const fieldType = (fieldName: string, { decoration_stats: decorationStats }: LogViewMessage, fields) => (isDecoratedField(fieldName, decorationStats)
  ? FieldType.Decorated
  : ((fields && fields.find((f) => f.name === fieldName)) || { type: FieldType.Unknown }).type);

const Message = ({ tableHeader, setActiveMessageDetailsId, highlight, completeMessage, fields }: MessageProps) => {
  const { message } = completeMessage;

  return (
    <LogViewRow key={`log-view-row-${message._id}`}
                onShowDetails={() => setActiveMessageDetailsId(message._id)}
                message={message}
                highlight={highlight}>
      {tableHeader.map((fieldName) => {
        const value = message[fieldName];

        return (
          <React.Fragment key={`log-view-cell-${message._id}-${fieldName}`}>
            <LogViewCell fieldName={fieldName}>
              {!isNullish(value) && (
                <CustomHighlighting field={fieldName} value={value}>
                  <TypeSpecificValue value={value}
                                     field={fieldName}
                                     type={fieldType(fieldName, completeMessage, fields)}
                                     render={DecoratedValue} />
                </CustomHighlighting>
              )}
            </LogViewCell>

            {fieldName !== tableHeader.last() ? CELL_SEP : null}
          </React.Fragment>
        );
      }).toArray()}
    </LogViewRow>
  );
};

type Props = {
  columns: LogViewWidgetConfig['fields'],
  pageRefs: PageRefs,
  tableRef: TableRef,
  fields: FieldTypeMappingsList,
  infiniteScrollDirection: InfiniteScrollDirection,
};

const LogViewTable = ({ columns, tableRef, pageRefs, fields, infiniteScrollDirection }: Props) => {
  const {
    actions: { loadNextPage, loadPrevPage, cancelLoadPrevPage },
    bottomPageId,
    loadedAllPrevMessages,
    pages,
  } = useContext(ListStateContext);
  const { stopAutoRefresh } = useAutoRefresh();
  const { setActiveMessageDetailsId, activeMessageDetails } = useContext(MessageDetailsContext);
  const _setActiveMessageDetailsId = useCallback((messageId: string) => {
    setActiveMessageDetailsId(messageId);
    stopAutoRefresh();
  }, [setActiveMessageDetailsId, stopAutoRefresh]);
  const _isInfiniteScrollUp = isInfiniteScrollUp(infiniteScrollDirection);
  const lastPageHeight = _getBottomPageHeight(bottomPageId, pageRefs);

  const _onTableScroll = () => onTableScroll({
    lastPageHeight,
    onTopReach: loadPrevPage,
    onBottomReach: loadNextPage,
    onBottomPageReach: cancelLoadPrevPage,
    tableRef,
  });

  // display pages in different order based on infinite scroll direction
  const sortedPages = isInfiniteScrollUp(infiniteScrollDirection) ? pages : pages.reverse();

  return (
    <Table ref={tableRef} onScroll={_onTableScroll}>
      {/* This div is required to position the header sticky in a scrollable div with Safari */}
      <TableInner>
        <LogViewHeader columns={columns} />
        {_isInfiniteScrollUp && !loadedAllPrevMessages && (
          <LoadMoreRow infiniteScrollDirection={infiniteScrollDirection} />
        )}
        {sortedPages.map(([pageId, messages]) => (
          // eslint-disable-next-line no-param-reassign
          <div ref={(ref) => { pageRefs.current[pageId] = ref; }} key={`page-${pageId}`}>
            {messages.map((message) => (
              <Message key={message?.message?._id}
                       tableHeader={columns}
                       fields={fields}
                       highlight={compareMessage(message, activeMessageDetails)}
                       setActiveMessageDetailsId={_setActiveMessageDetailsId}
                       completeMessage={message} />
            ))}
          </div>
        )).toArray()}
        {!_isInfiniteScrollUp && !loadedAllPrevMessages && (
          <LoadMoreRow infiniteScrollDirection={infiniteScrollDirection} />
        )}
      </TableInner>
    </Table>
  );
};

export default LogViewTable;
