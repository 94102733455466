import * as React from 'react';
import styled, { css } from 'styled-components';

import type { Stream } from 'stores/streams/StreamsStore';
import { Icon, Tooltip } from 'components/common';
import PreviewLakeLogsButton from 'data-lake/components/PreviewLakeLogsButton';
import useFeature from 'hooks/useFeature';

import useStreamDataLakeStatus from '../hooks/useStreamDataLakeStatus';

const Wrapper = styled.div<{ $enabled: boolean }>(
  ({ theme, $enabled }) => css`
    color: ${$enabled ? theme.colors.variant.success : theme.colors.variant.default};
    width: fit-content;
  `,
);

type Props = {
  stream: Stream;
};

const DataLakeCell = ({ stream }: Props) => {
  const { data: config, isFetching } = useStreamDataLakeStatus(stream.id);
  const previewFeatureIsEnabled = useFeature('data_warehouse_search');

  if (!stream.is_editable || isFetching) {
    return null;
  }

  const isDataLakeEnabled = config?.enabled || false;

  if (previewFeatureIsEnabled && isDataLakeEnabled) {
    return <PreviewLakeLogsButton streamId={stream.id} />;
  }

  return (
    <Tooltip withArrow position="right" label={`Data lake is ${isDataLakeEnabled ? 'enabled' : 'disabled'}`}>
      <Wrapper $enabled={isDataLakeEnabled}>
        <Icon name={isDataLakeEnabled ? 'check_circle' : 'cancel'} />
      </Wrapper>
    </Tooltip>
  );
};

export default DataLakeCell;
