import * as React from 'react';

import { LinkContainer } from 'components/common/router';
import { Button } from 'components/bootstrap';
import useFeature from 'hooks/useFeature';
import DataLakeRoutes from 'data-lake/Routes';

type Props = {
  streamId: string;
};

const PreviewLakeLogsButton = ({ streamId }: Props) => {
  const featureIsEnabled = useFeature('data_warehouse_search');

  if (!featureIsEnabled) {
    return null;
  }

  return (
    <LinkContainer to={`${DataLakeRoutes.PREVIEW}?streams=${streamId}`}>
      <Button bsSize="xs" title="Preview data lake logs">
        Preview logs
      </Button>
    </LinkContainer>
  );
};

export default PreviewLakeLogsButton;
