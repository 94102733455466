import * as React from 'react';
import { useMemo, useCallback, useState, useEffect, useRef, useContext } from 'react';

import type { TimeRange } from 'views/logic/queries/Query';
import type { Message } from 'views/components/messagelist/Types';
import LogViewWidget from 'logview/components/LogViewWidget';
import WidgetFrame from 'views/components/widgets/WidgetFrame';
import { WidgetHeader } from 'views/components/widgets';
import WidgetContainer from 'views/components/WidgetContainer';
import fetchSearchResults, { PAGE_SIZE } from 'data-lake/preview/fetchSearchResults';
import type { LogViewMessage } from 'logview/types';
import { IconButton } from 'components/common';
import EditWidgetFrame from 'views/components/widgets/EditWidgetFrame';
import WidgetContext from 'views/components/contexts/WidgetContext';
import type DataLakeWidget from 'data-lake/logic/DataLakeWidget';
import DataLakeLogViewEdit from 'data-lake/preview/DataLakeLogViewEdit';
import type DataLakeWidgetConfig from 'data-lake/logic/DataLakeWidgetConfig';
import type LogViewWidgetConfig from 'logview/logic/LogViewWidgetConfig';
import type { FieldFilters, FieldsOperator } from 'data-lake/Types';
import useOnBrowserNavigation from 'data-lake/hooks/useOnBrowserNavigation';
import type FieldType from 'views/logic/fieldtypes/FieldType';
import TypeSpecificValue from 'views/components/TypeSpecificValue';
import CustomHighlighting from 'views/components/highlighting/CustomHighlighting';
import FieldTypesContext from 'views/components/contexts/FieldTypesContext';

type EditWrapperProps = React.PropsWithChildren<{
  config: DataLakeWidgetConfig;
  editing: boolean;
  onCancel: () => void;
  onChangeWidgetConfig: (config: DataLakeWidgetConfig) => Promise<void>;
  onSubmit: (newWidget: DataLakeWidget, hasChanges: boolean) => Promise<void>;
  isFetching: boolean;
}>;

const EditWrapper = ({
  onCancel,
  editing,
  children = undefined,
  onSubmit,
  config,
  onChangeWidgetConfig,
  isFetching,
}: EditWrapperProps) => {
  if (!editing) {
    return children;
  }

  return (
    <EditWidgetFrame
      onCancel={onCancel}
      displaySubmitActions={false}
      onSubmit={onSubmit}
      containerComponent={React.Fragment}
      showQueryControls={false}>
      <DataLakeLogViewEdit config={config} onCancel={onCancel} isFetching={isFetching} onChange={onChangeWidgetConfig}>
        {children}
      </DataLakeLogViewEdit>
    </EditWidgetFrame>
  );
};

const ResetListState = ({
  resetListState,
  messages,
}: {
  resetListState: () => void;
  messages: Array<LogViewMessage>;
}) => {
  const prevMessages = useRef(messages);

  // reset list state after manual search execution
  useEffect(() => {
    if (prevMessages && prevMessages.current !== messages) {
      resetListState();
      prevMessages.current = messages;
    }
  }, [messages, resetListState]);

  useOnBrowserNavigation(resetListState);

  return null;
};

const messageListAfter = (messages: Array<LogViewMessage>) => {
  if (messages?.length === 0 || messages?.length < PAGE_SIZE) {
    return undefined;
  }

  const lastMessage = messages[messages.length - 1];

  return {
    timestamp: lastMessage.message.timestamp,
    id: lastMessage.message._id,
  };
};

type Props = {
  editing: boolean;
  searchRequestParams: {
    fields: Array<string>;
    filters: {
      stream: string;
      timerange: TimeRange;
      fields: { fieldFilters: Array<FieldFilters>; operator: FieldsOperator };
    };
  };
  messages: Array<LogViewMessage>;
  onChangeWidget: (newWidget: DataLakeWidget) => Promise<void>;
  onToggleEdit: () => void;
  widget: DataLakeWidget;
  isFetching: boolean;
};

const DLLogViewWidget = ({
  messages,
  onToggleEdit,
  widget,
  onChangeWidget,
  editing,
  searchRequestParams,
  isFetching,
}: Props) => {
  const fieldTypes = useContext(FieldTypesContext);
  const [loading, setLoading] = useState(false);
  const [configBeforeEdit, setConfigBeforeEdit] = useState<LogViewWidgetConfig>();

  const initialAfter = useMemo(() => messageListAfter(messages), [messages]);

  const toggleEditing = useCallback(() => {
    if (!editing) {
      setConfigBeforeEdit(widget.config);
    }

    onToggleEdit();
  }, [editing, onToggleEdit, widget.config]);
  const onLoadMessages = useCallback(
    async (searchAfter: { timestamp: string; id: string }) => {
      const prevMessages = await fetchSearchResults({
        ...searchRequestParams.filters,
        fields: searchRequestParams.fields,
        searchAfter,
        fieldFilters: searchRequestParams.filters.fields?.fieldFilters,
        fieldsOperator: searchRequestParams.filters.fields?.operator,
      });

      return {
        messages: prevMessages,
        after: messageListAfter(prevMessages),
      };
    },
    [searchRequestParams.fields, searchRequestParams.filters],
  );
  const onLoadMessage = useCallback((message: { message: { [fieldName: string]: unknown } }) => {
    const filteredFields = Object.fromEntries(
      Object.entries(message.message).filter(([_, value]) => value !== null && value !== undefined),
    );

    return Promise.resolve({
      formatted_fields: filteredFields,
      filtered_fields: filteredFields,
      fields: filteredFields,
    } as unknown as Message);
  }, []);

  const onChangeConfig = useCallback(
    (newConfig: DataLakeWidgetConfig) => onChangeWidget(widget.toBuilder().config(newConfig).build()),
    [onChangeWidget, widget],
  );

  const onCancelEdit = useCallback(() => {
    onChangeConfig(configBeforeEdit);
    toggleEditing();
  }, [configBeforeEdit, onChangeConfig, toggleEditing]);

  const onSubmitEdit = useCallback(
    (newWidget: DataLakeWidget, hasChanges: boolean) => {
      if (hasChanges) {
        return onChangeWidget(newWidget).then(() => toggleEditing());
      }

      toggleEditing();

      return Promise.resolve();
    },
    [onChangeWidget, toggleEditing],
  );

  const renderFieldValue = useCallback(
    (field: string, fieldType: FieldType, value: string) => (
      <CustomHighlighting field={field} value={value}>
        <TypeSpecificValue field={field} value={value} type={fieldType} />
      </CustomHighlighting>
    ),
    [],
  );

  return (
    <WidgetContext.Provider value={widget}>
      <WidgetContainer className="widgetFrame" isFocused>
        <WidgetFrame widgetId="data-wearhouse-log-view-widget">
          <WidgetHeader title="Data Lake Logs" hideDragHandle loading={loading} editing={editing}>
            {!editing && <IconButton name="edit_square" title="Edit" iconType="regular" onClick={toggleEditing} />}
          </WidgetHeader>
          <EditWrapper
            editing={editing}
            onSubmit={onSubmitEdit}
            onCancel={onCancelEdit}
            isFetching={isFetching}
            config={widget.config}
            onChangeWidgetConfig={onChangeConfig}>
            <LogViewWidget
              config={widget.config}
              initialAfter={initialAfter}
              infiniteScrollDirection="DOWN"
              messages={messages}
              onLoadMessages={onLoadMessages}
              renderFieldValue={renderFieldValue}
              editing={editing}
              onLoadMessage={onLoadMessage}
              setLoadingState={setLoading}
              fields={fieldTypes.all}
              onChangeConfig={onChangeConfig}>
              {({ resetListState }) => <ResetListState resetListState={resetListState} messages={messages} />}
            </LogViewWidget>
          </EditWrapper>
        </WidgetFrame>
      </WidgetContainer>
    </WidgetContext.Provider>
  );
};

export default DLLogViewWidget;
