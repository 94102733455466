import { Map, Set } from 'immutable';

import type { WidgetState } from 'views/logic/widgets/Widget';
import Widget from 'views/logic/widgets/Widget';
import type { QueryString, TimeRange } from 'views/logic/queries/Query';
import isDeepEqual from 'stores/isDeepEqual';
import isEqualForSearch from 'views/stores/isEqualForSearch';
import type MessagesWidget from 'views/logic/widgets/MessagesWidget';
import { MESSAGE_FIELD } from 'views/Constants';
import DataLakeWidgetConfig from 'data-lake/logic/DataLakeWidgetConfig';

export default class DataLakeWidget extends Widget {
  constructor(
    id: string,
    config: DataLakeWidgetConfig,
    timerange: TimeRange | undefined | null,
    query: QueryString | undefined | null,
    streams: Array<string> = [],
  ) {
    super(id, DataLakeWidget.type, config, undefined, timerange, query, streams, undefined, undefined);
  }

  static type = 'data-lake-logs';

  static defaultTitle = 'Untitled Log View';

  // eslint-disable-next-line class-methods-use-this
  get isExportable() {
    return true;
  }

  static fromJSON(value: WidgetState) {
    const { id, config, timerange, query, streams } = value;

    return new DataLakeWidget(id, DataLakeWidgetConfig.fromJSON(config), timerange, query, streams);
  }

  static fromMessageTable(messageTable: MessagesWidget): DataLakeWidget {
    const { showMessageRow, fields } = messageTable.config;
    const needsMessageField = showMessageRow && !fields.includes(MESSAGE_FIELD);

    const logViewFields = Set(needsMessageField ? [...fields, MESSAGE_FIELD] : fields);

    return DataLakeWidget.builder()
      .id(messageTable.id)
      .query(messageTable.query)
      .timerange(messageTable.timerange)
      .streams(messageTable.streams)
      .streamCategories(messageTable.streamCategories)
      .config(DataLakeWidgetConfig.createDefault().toBuilder().fields(logViewFields).build())
      .build();
  }

  equals(other: any) {
    if (other instanceof DataLakeWidget) {
      return ['id', 'config', 'timerange', 'query', 'streams'].every((key) =>
        isDeepEqual(this._value[key], other[key]),
      );
    }

    return false;
  }

  equalsForSearch(other: any) {
    if (other instanceof DataLakeWidget) {
      return ['id', 'config', 'timerange', 'query', 'streams'].every((key) =>
        isEqualForSearch(this._value[key], other[key]),
      );
    }

    return false;
  }

  toBuilder() {
    const { id, config, timerange, query, streams } = this._value;

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new Builder(Map({ id, config, timerange, query, streams }));
  }

  static builder() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new Builder();
  }
}

class Builder extends Widget.Builder {
  build(): DataLakeWidget {
    const { id, config, timerange, query, streams } = this.value.toObject();

    return new DataLakeWidget(id, config, timerange, query, streams);
  }
}
