import __request__ from 'routing/request';
interface MetricDto {
    readonly name: string;
    readonly type: 'METER';
}
type ForwarderInputStateDtoArray = ForwarderInputStateDto[];
interface ForwarderInputStateDto {
    readonly requested_state: 'CREATED' | 'SETUP' | 'INITIALIZED' | 'INVALID_CONFIGURATION' | 'STARTING' | 'RUNNING' | 'FAILED' | 'STOPPING' | 'STOPPED' | 'TERMINATED' | 'FAILING' | 'UNRECOGNIZED';
    readonly state: 'CREATED' | 'SETUP' | 'INITIALIZED' | 'INVALID_CONFIGURATION' | 'STARTING' | 'RUNNING' | 'FAILED' | 'STOPPING' | 'STOPPED' | 'TERMINATED' | 'FAILING' | 'UNRECOGNIZED';
    readonly metrics: MetricDto[];
    readonly input_id: string;
    readonly forwarder_id: string;
}
interface ForwarderInputStateDtoArrayMap {
    readonly [_key: string]: ForwarderInputStateDtoArray;
}
/**
 * Get all states for all Forwarders and Inputs
 */
export function all(): Promise<ForwarderInputStateDtoArrayMap> {
    return __request__('GET', '/plugins/org.graylog.plugins.forwarder/forwarder/inputstates', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get all states for the given Forwarder
 */
export function allForForwarder(forwarderId: string): Promise<ForwarderInputStateDtoArrayMap> {
    return __request__('GET', `/plugins/org.graylog.plugins.forwarder/forwarder/inputstates/forwarder/${forwarderId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get state for the given Forwarder and Input
 */
export function forForwarderAndInput(forwarderId: string, inputId: string): Promise<ForwarderInputStateDtoArrayMap> {
    return __request__('GET', `/plugins/org.graylog.plugins.forwarder/forwarder/inputstates/forwarder/${forwarderId}/input/${inputId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Request that the given input should be started on the Forwarder
 */
export function startInput(forwarderId: string, inputId: string): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.forwarder/forwarder/inputstates/forwarder/${forwarderId}/input/${inputId}/requested_state/start`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Request that the given input should be stopped on the Forwarder
 */
export function stopInput(forwarderId: string, inputId: string): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.forwarder/forwarder/inputstates/forwarder/${forwarderId}/input/${inputId}/requested_state/stop`, null, {}, {
        'Accept': ['application/json']
    });
}
